import React, {useState} from 'react';
import http from '../../http-common.js'
import { useNavigate} from 'react-router-dom'
import './login.css'

export default function LoginMobile({authenticate}) {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    let navigate = useNavigate();

    const authUser = async () => {
        const dataObj = {
            userName: userName,
            password: password
        }

        try{
            await http.post('user', dataObj).then(response => {
                if (response['data']['message']){
                    setError(response['data']['message'])
                } else {
                    localStorage.setItem('user', JSON.stringify({'userName' : userName, isAdmin: true}))
                    authenticate()
                    navigate('/admin')
                }
            }
            )
            } catch (err){
            console.error(err.response.data);
            }
    }
  return <div className='login'>
      <div>Admin Login</div>
      <div>User Name</div>
      <input className='username login_input' type="email" onChange={e => setUserName(e.target.value)}/>
      <div>Password</div>
      <input className='password login_input' type="password" onChange={e => setPassword(e.target.value)}/>
      {error && <div>{error}</div>}
      <button className='login_button_mobile' onClick={authUser}>Login</button>
  </div>;
}
