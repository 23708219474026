import React from 'react';
import {createMedia} from "@artsy/fresnel"
import BillingMobile from '../../components/billing/BillingMobile';
import BillingDesktop from '../../components/billing/BillingDesktop';

export default function Billing() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <BillingMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BillingDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}