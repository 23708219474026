import React, {useState, useEffect} from 'react';
import http from '../../http-common.js'
import getTodaysDate from '../../tools/date.js';
import { Link } from 'react-router-dom';
import './manageOrders.css'

export default function ManageOrdersMobile() {
  const [orders, setOrders] = useState('')
  const [searchSelection, setSearchSelection] = useState("3")

  useEffect(()=> {
    getOrders()
  }, [searchSelection])

  const getOrders = async() => {
    try {
      let searchRequest;
      if (searchSelection === '3'){
        searchRequest = 'all';
      } else if (searchSelection === '2') {
        searchRequest =  true
      } else if (searchSelection === '1'){
        searchRequest =  false
      }
      const orderData = await http.get(`orders?shipped=${searchRequest}`)
      setOrders(orderData['data'])
    } catch (err) {
      console.log(err)
    }
  }

  const checkOrderBox = (id) => {
    const date = getTodaysDate()
    http.put('orders', {_id: id, payload: {'shipped': true, 'dateShipped': date}})
    getOrders()
  }

  const handleDropdown = (e) => {
    setSearchSelection(e.target.value)
  }

  return <div className='manage_orders'>
    <div className='manage_orders_header'>
    Manage Orders
    </div>
    <div className='manage_order_back_button_wrapper'>
        <Link to='/admin'>
        <button className='manage_order_back_button'>{`<--Back`}</button>
        </Link>
      </div>
    <select className='checkout_amt_dropdown' onChange={handleDropdown} name="option" id="dropdown_option" value={searchSelection}>
          <option value="1">Yet to Be Shipped</option>
          <option value="2">Already Shipped</option>
          <option value="3">All Orders</option>
      </select>
    <div>
    {(orders.length === 0 && searchSelection === '1') && 
    <div> All Orders Have Been Shipped</div>}
    {orders && orders.map((order, idx) => (
          <div key={'order_' + idx} className='order_display'>
          <div className='order_important'>{order["name"]}</div>
          <div>{order["order"]}</div>
          <div>street: {order["shippingAddressLine1"]} {order["shippingAddressLine2"]}</div>
          <div>city: {order["shippingCity"]}</div>
          <div>state: {order["shippingState"]}</div>
          <div>zipcode: {order["shippingZipcode"]}</div>
          <div className='order_important'>ID: {order["orderNumber"]}</div>
          {order['shipped'] && <div className='order_important'>Shipped On: {order["dateShipped"]}</div>}
          <div>
          {!order['shipped'] && 
          <div>
          <input onChange={() =>checkOrderBox(order["_id"])} type="checkbox" id="billing" name="billing" value="billing"/>
          <label for="billing"> Order sent</label>
          </div>}
          
          </div>
          
          
          </div>
      )
      )}
    <div>-----</div>
    </div>
    </div>;

}
