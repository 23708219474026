import React from 'react';
import {createMedia} from "@artsy/fresnel"
import LoginMobile from '../../components/login/LoginMobile';
import LoginDesktop from '../../components/login/LoginDesktop';

export default function Login({authenticate}) {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <LoginMobile authenticate={() => authenticate()}/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <LoginDesktop authenticate={() => authenticate()}/>
              </Media>
          </MediaContextProvider>
  </div>;
}