import React from 'react';
import { Link } from 'react-router-dom';
import './adminDashboard.css'

export default function AdminDashboardDesktop() {
  return <div>
    <div className='admin_dash'>
      <div className='admin_dash_header'>Admin Dashboard</div>
      <div className='dash_link_container_wrapper'>
      <Link className='dash_link' to ='/'>
        <div className='dash_link_container'>
        Home
          </div>
        </Link>
        <Link className='dash_link' to ='/post'>
        <div className='dash_link_container'>
        Write Blog
          </div>
        </Link>
        <Link className='dash_link' to ='/manageBlog'>
        <div className='dash_link_container'>
        Manage Blog
          </div>
        </Link>
        <Link className='dash_link' to ='/manageOrders'>
        <div className='dash_link_container'>
        Manage Orders
          </div>
        </Link>
      </div>
    </div>
  </div>;
}
