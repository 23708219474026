import React, {useState, useEffect} from 'react';
import http from '../../http-common.js'
import { Link } from 'react-router-dom';
import './manageblog.css'

export default function ManageBlogMobile() {
    const [data, setData] = useState([{author: 'loading', title: 'loading', body: 'loading'}])
    const [focus, setFocus] = useState(0)
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [edit, setEdit] = useState(false)
    const [read, setRead] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false) 

    useEffect(()=> {
        getblogData()
    }, [])

    const getblogData = async() =>{
        try{
        const blogData = await http.get('blog?latest=true')
        setData(blogData['data'])
        } catch (err){
        console.error(err);
        }
    }

    const editBlog = (postNumber) => {
        setFocus(postNumber)
        setEdit(true)
        setTitle(data[postNumber]['title'])
        setBody(data[postNumber]['body'])
    }

    const deleteBlog = (postNumber) => {
        setFocus(postNumber)
        setDeleteConfirm(true)
    }

    const readBlog = (postNumber) => {
        setFocus(postNumber)
        setRead(true)
    }

    const handleString = (str) => {
        if (str.length >= 150){
            return str.substring(0,150) + '...'
        } else {
            return str
        }
    }

    const postEditBlog = async () => {
        await http.put('blog',{
            _id: data[focus]['_id'],
            payload: {
            'title': title,
            'body': body,
            'author': data[focus]['author'],
            'postNumber': data[focus]['postNumber']
            }
        }).then(() => getblogData())
        setEdit(false)
    }

    const deleteConfirmSubmit = () => {
        const idOfBlogToDelete = data[focus]['_id']
        http.delete(`blog?_id=${idOfBlogToDelete}`)
        getblogData()
        setDeleteConfirm(false)
    }

    const goBack = () => {
        setEdit(false)
        setRead(false)
        setDeleteConfirm(false)
    }
 
  return <>
  <div className='manage_blog'>
      <div className='manage_blog_header'>
        Manage Blog
      </div>
      <div className='manage_blog_back_button_wrapper'>
        <Link to='/admin'>
        <button className='manage_blog_back_button'>{`<--Back`}</button>
        </Link>
      </div>
      {!edit && !read && !deleteConfirm && data && data.map((data, idx) => (
          <div key={'blog_' + idx} className='manage_blog_display'>
          <div className='blog_title_home'>{data["title"]}</div>
          <div className='blog_body_home'>{handleString(data["body"])}</div>
          <div>
          <button className='delete_blog_button' onClick={() => deleteBlog(idx)}> Delete</button>
          <button className='edit_blog_button' onClick={() => editBlog(idx)}> Edit</button>
          <button className='read_blog_button' onClick={() => readBlog(idx)}>Read</button>
          </div>
          </div>
      )
      )}
      {edit && <div className='edit_blog'>
      <div className='edit_blog_title'></div>
      <input onChange={(e) => setTitle(e.target.value)} type="text" placeholder='title' value={title}/>
      <textarea onChange={(e) => setBody(e.target.value)} name="body" id="" cols="30" rows="10" placeholder='write something...' value={body}/>
      <button className='post_blog_button' onClick={goBack}>Back</button>
      <button className='post_blog_button' onClick={postEditBlog}>Submit</button>

      <button></button>
      </div>}
    {read && 
        <div>
            <div className='read_display'>
            <div className='manage_blog_title'>{data[focus]["title"]}</div>
            <div className='manage_blog_body'>{data[focus]["body"]}</div>
            </div>
            <button className='post_blog_button' onClick={goBack}>Back</button>
             </div>
        }
    {deleteConfirm &&  
            <div>
            <div className='delete_display'>Are you sure you want to delete {data[focus]['title']}?</div>
            <button className='post_blog_button' onClick={goBack}>No</button>
            <button className='post_blog_button' onClick={deleteConfirmSubmit}>Yes</button>
            </div>
        }
    
      <div> --- </div>
  </div>
    
      
      </>;
}
