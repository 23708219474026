import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './success.css'

export default function SuccessMobile() {
  const [orderNumber, setOrderNumber] = useState(null)

  useEffect(() => {
    if (localStorage.getItem('order_number') !== null){
      setOrderNumber(localStorage.getItem('order_number'))
    }
  }, [])

  return <div className='success'>
    <h3 className='success_text_mobile'>
      Your order has been successfully placed!
      Your confirmation number is {orderNumber} .
      You will receive an email with order details shortly
    </h3>
    <div className='success_bottom_half'>
    <Link to='/' onClick={() => localStorage.clear()}>
      <button className='cart_continue success_button'> Home</button>
      </Link>
    </div>
  </div>;
}
