import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'

export default function Navbar() {
  const [isAdmin, setisAdmin] = useState(false)

  useEffect(() => {
    isAdminCheck()
  }, [])
  
  const isAdminCheck = () => {
    if (localStorage.getItem('user') === null){
      setisAdmin(false);
    } else if (localStorage.getItem('user') !== null){
      const user = JSON.parse(localStorage.getItem('user'))
      if (user['isAdmin'] === true){
        setisAdmin(true)
      }
    }
  }

const handleLogout = () => {
  localStorage.removeItem('user')
  setisAdmin(false)
}

  return (
  <div className='navbar'>
      <div className='wrapper'>
              <a href="#intro" className='logo'> 
              M. A. Turner
              </a>
              <div className='options'>
                <a href='#showcase' className='option' > Shop</a>
                <a href="#latestBlog" className='option'>Blog</a>
                <a href="#contact" className='option' >Contact</a>
                {isAdmin && <Link to='/admin' className='option'> Admin</Link>}
                {isAdmin && <div className='option' onClick={handleLogout}>Logout</div>}
              </div>
      </div>
  </div>
  )}
