import React from 'react';
import Contact from '../../components/contact/Contact';
import Intro from '../../components/intro/Intro';
import LatestBlog from '../../components/latestBlog/LatestBlog';
import Navbar from '../../components/navbar/Navbar';
import Showcase from '../../components/showcase/Showcase';
import {createMedia} from "@artsy/fresnel"
import NavbarDesktop from '../../components/navbar/NavbarDesktop';
import IntroDesktop from '../../components/intro/IntroDesktop';
import ShowcaseDesktop from '../../components/showcase/ShowcaseDesktop';
import LatestBlogDesktop from '../../components/latestBlog/LatestBlogDesktop';
import ContactDesktop from '../../components/contact/ContactDesktop';


export default function Main() {

  const {MediaContextProvider, Media} = createMedia({
    breakpoints: {
      sm: 0, 
      lg: 1024,
    }
  })
  return <div>
    <MediaContextProvider>
      <Media at='sm'>
      <Navbar className='app-section'/>
      <Intro className='app-section'/>
      <Showcase className='app-section'/>
      <LatestBlog className='app-section'/>
      <Contact className='app-section'/>
      </Media>
      <Media greaterThanOrEqual='lg'>
      <NavbarDesktop className='app-section'/>
      <IntroDesktop className='app-section'/>
      <ShowcaseDesktop className='app-section'/>
      <LatestBlogDesktop className='app-section'/>
      <ContactDesktop/>
      </Media>
    </MediaContextProvider>
  </div>;
}
