import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import orderGen from '../../tools/orderGen';
import './success.css'

export default function SuccessDesktop() {
  const [orderNumber, setOrderNumber] = useState('#TSG-000-001')

  useEffect(() => {
    if (localStorage.getItem('order_number') !== null){
      setOrderNumber(localStorage.getItem('order_number'))
    }
  }, [])

  return <div className='success'>
    <h3 className='success_text_desktop'>
      Your order has been successfully placed!
    </h3>
    <h3 className='success_text_desktop'>
    Your order number is <b>{orderNumber}</b> . 
    </h3>
    <h3 className='success_text_desktop'>
    You will receive an email with order details shortly.
    </h3>
    <div className='success_bottom_half_desktop'>
    <Link to='/' onClick={() => localStorage.clear()}>
      <button className='cart_continue success_button'> Home</button>
      </Link>
    </div>
  </div>;
}
