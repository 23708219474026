import './App.css'
import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Main from './pages/main/Main';
import Cart from './pages/shopping/Cart'
import Shipping from './pages/shopping/Shipping';
import Billing from './pages/shopping/Billing';
import StripePay from './pages/shopping/StripePay';
import Blog from './pages/blog/Blog';
import Success from './pages/shopping/Success';
import Login from './pages/admin/Login'
import AdminDashboard from './pages/admin/AdminDashboard'
import PostBlog from './pages/admin/PostBlog';
import ManageBlog from './pages/admin/ManageBlog';
import ManageOrders from './pages/admin/ManageOrders';




function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("user") !== null){
        setAuth(true)
    }
  }, [])

  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/shipping' element={<Shipping/>} />
        <Route path='/billing' element={<Billing/>} />
        <Route path='/pay' element={<StripePay/>}/>
        <Route path='/blog' element={<Blog/>} />
        <Route path='/success' element={<Success/>}/> 
        <Route path='/login' element={<Login authenticate={() => setAuth(true)}/>}/> 
        {auth && <>
          <Route path='/admin' element={<AdminDashboard/>}/> 
          <Route path='/post' element={<PostBlog/>}/> 
          <Route path='/manageBlog' element={<ManageBlog/>}/> 
          <Route path='/manageOrders' element={<ManageOrders/>}/>
        </>}
      </Routes>
    </div>
    </Router> 
  );
}

export default App;
