import React, {useState} from 'react';
import http from '../../http-common';
import './contact.css'

export default function Contact() {
  const [name, setName ] = useState('')
const [email, setEmail] = useState('')
const [message, setMessage] = useState('')

const sendMessage = async () => {
  const dataObj = {
    subject: `${name} from ${email} sent a message from your site!`,
    message: message,
  }

  try {
    await http.post('mail', dataObj)
    .then(setName(''))
    .then(setEmail(''))
    .then(setMessage(''))
  } catch (error) {
      console.error(error)
  }
}
  return (
  <div id='contact' className='contact'>
    <div className='contactMessage'>
      Send me a messsage here or at MaryAnnTurner@yahoo.com
    </div>
    <form action="" className='form'>
      <label htmlFor="name">Name</label>
      <input onChange={(e) => setName(e.target.value)} type="text" placeholder='Your name..' value={name}/>

      <label htmlFor="email">Email</label>
      <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder='Your email..' value={email}/>

      <label htmlFor="message">Message</label>
      <textarea onChange={(e) => setMessage(e.target.value)} name="subject" id="" cols="30" rows="10" placeholder="What's up?" value={message}></textarea>

      <input id='contact_submit' onClick={sendMessage} type="submit" value='Submit'/>
    </form>
  </div>
    )
}
