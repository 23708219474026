import React, {useState, useEffect} from 'react';
import { Link, useNavigate} from 'react-router-dom'
import './stripePay.css';
import Stripe from 'react-stripe-checkout';
import orderGen from '../../tools/orderGen';
import http from '../../http-common';

export default function StripePayMobile() {
    let navigate = useNavigate();
    const [total, setTotal] = useState('')
    const [amount, setAmount] = useState('')
    const [error, setError] = useState(null)

    const [shippingFirstName, setShippingFirstName] = useState('')
    const [shippingLastName, setShippingLastName] = useState('')
    const [shippingAddressLine1, setShippingAddressLine1] = useState('')
    const [shippingAddressLine2, setShippingAddressLine2] = useState('')
    const [shippingCity, setShippingCity] = useState('')
    const [shippingState, setShippingState] = useState('')
    const [shippingZipcode, setShippingZipcode] = useState('')
    const [shippingPhoneNumber, setShippingPhoneNumber] = useState('')

    const [billingFirstName, setBillingFirstName] = useState('')
    const [billingLastName, setBillingLastName] = useState('')
    const [billingAddressLine1, setBillingAddressLine1] = useState('')
    const [billingAddressLine2, setBillingAddressLine2] = useState('')
    const [billingCity, setBillingCity] = useState('')
    const [billingState, setBillingState] = useState('')
    const [billingZipcode, setBillingZipcode] = useState('')
    const [orderNumber, setOrderNumber] = useState(null)

    useEffect(() => {
        if (localStorage.getItem('cart') !== null){
            const importedCartObject = JSON.parse(localStorage.getItem('cart'))
            setTotal(importedCartObject['total'])
            setAmount(importedCartObject['amount'])
        }
        if (localStorage.getItem('shipping') !== null){
            const importedShippingObject = JSON.parse(localStorage.getItem('shipping'))
            setShippingFirstName(importedShippingObject['firstName'])
            setShippingLastName(importedShippingObject['lastName'])
            setShippingAddressLine1(importedShippingObject['addressLine1'])
            setShippingAddressLine2(importedShippingObject['addressLine2'])
            setShippingCity(importedShippingObject['city'])
            setShippingState(importedShippingObject['state'])
            setShippingZipcode(importedShippingObject['zipcode'])
            setShippingPhoneNumber(importedShippingObject['phoneNumber'])
        }
        if (localStorage.getItem('billing') !== null){
            const importedBillingObject = JSON.parse(localStorage.getItem('billing'))
            setBillingFirstName(importedBillingObject['firstName'])
            setBillingLastName(importedBillingObject['lastName'])
            setBillingAddressLine1(importedBillingObject['addressLine1'])
            setBillingAddressLine2(importedBillingObject['addressLine2'])
            setBillingCity(importedBillingObject['city'])
            setBillingState(importedBillingObject['state'])
            setBillingZipcode(importedBillingObject['zipcode'])
        }
        const newOrderNumber = orderGen('TSG')
        setOrderNumber(newOrderNumber)
        localStorage.setItem('order_number', newOrderNumber)
    }, [])

    const handleToken = async (totalAmount, token) => {
        try {
            await http.post('stripe', {
                token: token.id,
                amount: totalAmount,
                email: token.email,
                name: billingFirstName + ' ' + billingLastName,
                billingCity: billingCity,
                billingAddressLine1: billingAddressLine1,
                billingAddressLine2: billingAddressLine2,
                billingState: billingState,
                billingZipcode: billingZipcode,
                shippingName: shippingFirstName + ' ' + shippingLastName,
                shippingCity: shippingCity,
                shippingAddressLine1: shippingAddressLine1,
                shippingAddressLine2: shippingAddressLine2,
                shippingZipcode: shippingZipcode,
                shippingState: shippingState,
                shippingPhoneNumber: shippingPhoneNumber,
                metadata: {'order': `${amount} x The Spiritual Guide to Tectonic Events`, orderNumber: orderNumber}
            })
            .then(
                await http.post('orders',{
                amount: totalAmount,
                email: token.email,
                name: billingFirstName + ' ' + billingLastName,
                billingCity: billingCity,
                billingAddressLine1: billingAddressLine1,
                billingAddressLine2: billingAddressLine2,
                billingState: billingState,
                billingZipcode: billingZipcode,
                shippingName: shippingFirstName + ' ' + shippingLastName,
                shippingCity: shippingCity,
                shippingAddressLine1: shippingAddressLine1,
                shippingAddressLine2: shippingAddressLine2,
                shippingZipcode: shippingZipcode,
                shippingState: shippingState,
                shippingPhoneNumber: shippingPhoneNumber,
                order: `${amount} x The Spiritual Guide to Tectonic Events`,
                orderNumber: orderNumber,
                shipped: false
            }))
            .then(
                await http.post('mail',{
                    subject: `YOU SOLD A BOOK! ${billingFirstName} ${billingLastName} just bought ${amount} copies of The Spiritual Guide to Tectonic Events!`,
                    message: `The book needs to be shipped to: ${shippingFirstName} ${shippingLastName},
                    ${shippingAddressLine1} ${shippingAddressLine2}, ${shippingState}, ${shippingZipcode}. 
                    The order is ${amount} x The Spiritual Guide to Tectonic Events.
                    Order confirmation number is ${orderNumber}. The email provided with the payment is ${token.email}.` 
            })) 
            .then(response => navigate(response['data']['redirect']))
        } catch (error) {
            console.log(error)
            setError('Sorry, there was a error processing your card. Please try again')

        }
    }
    const tokenHandler = (token) => {
        handleToken(safeTotal(total), token)
    }

    const safeTotal = (num) => {
        return Math.round((((num * 100) / 100).toFixed(2)) * 100)
    }

  return <div className='stripe'>
       <div className='mobile_regular_display'>
      <div>
          <h3>Review and Pay</h3>
      </div>
      <div className='review_info'>
      <div className='review_module'>
          <h3>Billing Address</h3>
          <div>{`${billingFirstName} ${billingLastName}`}</div>
          <div>{billingAddressLine1}</div>
          <div>{billingAddressLine2}</div>
          <div>{`${billingCity}, ${billingState}, ${billingZipcode}`}</div>
          <Link to='/billing'>
          <button className='stripe_continue'>Edit</button>
          </Link>
      </div>
      <div className='review_module'>
          <h3>Shipping Address</h3>
          <div>{`${shippingFirstName} ${shippingLastName}`}</div>
          <div>{shippingAddressLine1}</div>
          <div>{shippingAddressLine2}</div>
          <div>{`${shippingCity}, ${shippingState}, ${shippingZipcode}`}</div>
          <Link to='/shipping'>
          <button className='stripe_continue'>Edit</button>
          </Link>
      </div>
      <div className='review_module'>
          <h3>Order</h3>
          <img className='stripe_cover' src="Book_Cover.jpg" alt="" />
          <div>{amount} x The Spiritual Guide to Tectonic Events</div>
          <div>{'$'}{total}</div>
          <Link to='/cart'>
            <button className='stripe_continue'>Edit</button>
          </Link>   
      </div>
      </div>
      <div className='stripe_button'>
    <div>
        <h3>Confirm</h3>
        {error && <div>{error}</div>}
    </div>

      <Stripe
      description='The Spiritual Guide to Tectonic Events'
      image='Book_Cover.jpg'
      label='Pay'
      amount={safeTotal(total)}
      stripeKey="pk_live_51KKnJ3FwtP12fnQtF4SToG9irttTmlaimjfnit0KhGvNkenlCvhfHTtdmKfLKuFmgLvObAXQBDStXlGvykfWm1JJ00uwn8m6jF"
      token={tokenHandler} 
      >
    <button onClick={() => {setError(null)}} className='stripe_continue'>Payment</button>
      </Stripe>
      </div>
      </div>
  </div>;
}
