import React from 'react';
import './intro.css'

export default function IntroDesktop() {
  return <div>
       <div className='intro_desktop'>
        <div className='intro_desktop_left'>
        <img className='headshot_desktop' src="MA_Turner_Face.jpg" alt="" />
        </div>
        <div className='intro_desktop_right'>
        <p className='bio_desktop'> MA Turner is an: artist, writer, musician, business owner/entreprenuer, and all around creative being born and raised in Northern California where she currently resides with her husband of 35 years and their 3 fur-babies. After having raised a beautiful son and assisted in running companies, she figured it was time to pursue her lifelong dreams and better utilize her remaining time in this earthly dimension.
        </p>
        </div>
    </div>
  </div>;
}
