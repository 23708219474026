import React from 'react';
import {createMedia} from "@artsy/fresnel"
import ShippingMobile from '../../components/shipping/ShippingMobile';
import ShippingDesktop from '../../components/shipping/ShippingDesktop';

export default function Shipping() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <ShippingMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <ShippingDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}
