import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import http from '../../http-common.js';
import './latestBlog.css'

export default function LatestBlogDesktop() {
  const [data, setData] = useState([{title: 'Welcome to my Blog', body: ''}])

  useEffect(()=> {
    getblogData()
}, [])

  const handleString = (str) => {
    if (str.length >= 210){
        return str.substring(0,210) + '...'
    } else {
        return str
    }
}

const getblogData = async() =>{
  try{
  const blogData = await http.get('blog?latest=true&limit=1')
  setData(blogData['data'])
  } catch (err){
  console.error(err);
  }
}
  return <div id='latestBlog' className='latestBlog'>
  <div className='blog_frontpage'>
  <div className='title_latest_blog_desktop'>{data[0]['title']}</div>
    <div className='content_desktop'>{handleString(data[0]['body'])}</div>
  </div>

  <div className='keepReading_desktop'>
    <Link to='/blog'>
    <button className='keepReadingButton_desktop'>{`Keep Reading ->`}</button>
    </Link>
  </div>
</div>
}
