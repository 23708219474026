import React from 'react';
import {createMedia} from "@artsy/fresnel"
import ManageBlogMobile from '../../components/manageBlog/ManageBlogMobile';
import ManageBlogDesktop from '../../components/manageBlog/ManageBlogDesktop';

export default function ManageBlog() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <ManageBlogMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <ManageBlogDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}