import React, {useState} from 'react';
import http from '../../http-common.js'
import getTodaysDate from '../../tools/date.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './postblog.css'

export default function PostBlogDesktop() {
    let navigate = useNavigate();

    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')

    const postBlog = async () => {
        const dataObj = {
            title: title,
            body: body,
            author: 'MATurner',
            date: getTodaysDate()
        }

        try{
            await http.post('blog', dataObj)
            .then(setBody('')).then(setTitle(''))
            .then(navigate('/admin'))


            } catch (err){
            console.error(err);
            }
    }

  return <div>
      <div className='post_blog'>
      <div className='post_blog_title_desktop'>Post a blog</div>
      <input id='post_blog_input_desktop' onChange={(e) => setTitle(e.target.value)} type="text" placeholder='title' value={title}/>
      <textarea onChange={(e) => setBody(e.target.value)} name="body" id="post_blog_textarea_desktop" cols="30" rows="40" placeholder='write something...' value={body}/>
      <div className='post_blog_button_wrapper_desktop'>
      <Link to='/admin'><button className='post_blog_button_desktop'>Back</button></Link>
      <button className='post_blog_button_desktop' onClick={postBlog}>Submit</button>
      </div>
  
      </div>
     
  </div>;
}
