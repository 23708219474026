import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import './navbar.css'

export default function NavbarDesktop() {
    const [isAdmin, setisAdmin] = useState(false)

    useEffect(() => {
      isAdminCheck()
    }, [])
    
    const isAdminCheck = () => {
      if (localStorage.getItem('user') === null){
        setisAdmin(false);
      } else if (localStorage.getItem('user') !== null){
        const user = JSON.parse(localStorage.getItem('user'))
        if (user['isAdmin'] === true){
          setisAdmin(true)
        }
      }
    }
  
  const handleLogout = () => {
    localStorage.removeItem('user')
    setisAdmin(false)
  }

  return <div className='navbar'>
        <div className='wrapper_desktop'>
              <a href="#intro" className='logo_desktop'> 
              M. A. Turner
              </a>
              <div className='options_desktop'>
                <a href='#showcase' className='option_desktop' > Shop</a>
                <a href="#latestBlog" className='option_desktop'>Blog</a>
                <a href="#contact" className='option_desktop' >Contact</a>
                {isAdmin && <Link to='/admin' className='option_desktop'> Admin</Link>}
                {isAdmin && <div className='option_desktop' onClick={handleLogout}>Logout</div>}
              </div>
      </div>
  </div>;
}
