import React from 'react';
import { Link } from 'react-router-dom';
import './showcase.css'

export default function ShowcaseDesktop() {
  return <div id='showcase' className='showcase_desktop'>
    <div className='showcase_desktop_left'>
    <img className='cover_desktop' src="Book_Cover.jpg" alt="" />
    </div>
    <div className='showcase_desktop_right'>
    <p className='desc_desktop'>
    <em>The Spiritual Guide to Tectonic Events</em> recounts my personal journey through difficult times over my lifetime and how I dealt with my fears and anxities in my own way - with humor and perserverance. I believe it can offer assistance to other who are faced with whatever this earthly realm may be choosing to present or manifest for them. Available on Amazon in both paperback or Kindle format. Or, if you order directly from me you will receive an autographed copy!
    </p>
    <div className='button_group_desktop'>
      <a href="https://www.amazon.com/dp/B09C2W8C34/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1">
      <button className='button_desktop'> Buy on Amazon</button>
      </a>
    <Link to='/cart'>
    <button className='button_desktop'> Buy Here</button>
    </Link>
   </div>  
    </div>
    
</div>;
}
