import React, {useState} from 'react';
import http from '../../http-common';
import './contact.css'

export default function ContactDesktop() {
const [name, setName ] = useState('')
const [email, setEmail] = useState('')
const [message, setMessage] = useState('')

const sendMessage = async () => {
  const dataObj = {
    subject: `${name} from ${email} sent a message from your site!`,
    message: message,
  }

  try {
    await http.post('mail', dataObj)
    .then(setName(''))
    .then(setEmail(''))
    .then(setMessage(''))
  } catch (error) {
      console.error(error)
  }
}

  return <div>
    <div id='contact' className='contact_desktop'>
    <div className='contact_left_desktop'>
    <div className='contact_message_desktop'>
      Send me a messsage here or at MaryAnnTurner@yahoo.com
    </div>
    <div>
      {`---->`}
    </div>
    </div>
    <div className='contact_right_desktop'>
    <form action="" className='form'>
      <label htmlFor="name">Name</label>
      <input onChange={(e) => setName(e.target.value)} type="text" placeholder='Your name..' value={name}/>

      <label htmlFor="email">Email</label>
      <input onChange={(e) => setEmail(e.target.value)} type="text" placeholder='Your email..' value={email}/>

      <label htmlFor="message">Message</label>
      <textarea onChange={(e) => setMessage(e.target.value)} name="subject" id="" cols="30" rows="10" placeholder="What's up?" value={message}></textarea>

      <input onClick={sendMessage} type="submit" value='Submit' className='submit_desktop'/>
    </form>
    </div>
    
  </div>
  </div>;
}
