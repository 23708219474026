import React from 'react';
import {createMedia} from "@artsy/fresnel"
import StripePayMobile from '../../components/stripePay/StripePayMobile';
import StripePayDesktop from '../../components/stripePay/StripePayDesktop';

export default function StripePay() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <StripePayMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <StripePayDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}
