import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './shipping.css'

//billing address code included here as well
export default function ShippingDesktop() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [billingIsShipping, setBillingisShipping] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('shipping') !== null){
       const importedShippingObject = JSON.parse(localStorage.getItem('shipping'))
       setFirstName(importedShippingObject['firstName'])
       setLastName(importedShippingObject['lastName'])
       setAddressLine1(importedShippingObject['addressLine1'])
       setAddressLine2(importedShippingObject['addressLine2'])
       setCity(importedShippingObject['city'])
       setState(importedShippingObject['state'])
       setZipcode(importedShippingObject['zipcode'])
       setPhoneNumber(importedShippingObject['phoneNumber'])
       setEmail(importedShippingObject['email'])
    }
  }, [])

  const sendShippingInfoToLocalStorage = () => {
    const shippingObject = {
      'firstName': firstName,
      'lastName': lastName,
      'addressLine1': addressLine1,
      'addressLine2': addressLine2,
      'city': city,
      'state': state,
      'zipcode': zipcode,
      'phoneNumber': phoneNumber,
      'email': email,
    }
    localStorage.setItem('shipping', JSON.stringify(shippingObject))

    if (billingIsShipping){
      const billingObject = {
        'firstName': firstName,
        'lastName': lastName,
        'addressLine1': addressLine1,
        'addressLine2': addressLine2,
        'city': city,
        'state': state,
        'zipcode': zipcode,
        'phoneNumber': phoneNumber,
        'email': email,
      }
      localStorage.setItem('billing', JSON.stringify(billingObject))
    }
    
  }

  return <div className='shipping'>
      <h3 className='shipping_header_desktop'>
          Shipping Address
      </h3>
      <div className='shipping_form_container'> 
      <form action="" className='shipping_form_desktop'>
      <label htmlFor="fname">First Name</label>
      <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)}placeholder='First Name..' />

      <label htmlFor="lname">Last Name</label>
      <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder='Last Name..' />

      <label htmlFor="address1">Street Address</label>
      <input type="text" value={addressLine1} onChange={e => setAddressLine1(e.target.value)} placeholder='1234 Street Ave...' />

      <label htmlFor="address2">Address Line 2</label>
      <input type="text" value={addressLine2} onChange={e => setAddressLine2(e.target.value)} placeholder='Apt 1...' />

      <label htmlFor="city">City</label>
      <input type="text" value={city} onChange={e => setCity(e.target.value)} placeholder='Cityville...' />

      <label htmlFor="state">State</label>
      <input type="text" value={state} onChange={e => setState(e.target.value)} placeholder='CA...' />

      <label htmlFor="zip">Zipcode</label>
      <input type="text" value={zipcode} onChange={e => setZipcode(e.target.value)} placeholder='12345...' />

      <label htmlFor="phone">Phone Number</label>
      <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} placeholder='(Optional) ...' />

      {/* <label htmlFor="email">Email</label>
      <input type="text" value={email} onChange={e => setEmail(e.target.value)}  placeholder='Needed...' /> */}

      <input type="checkbox" id="billing" name="billing" value="billing" onChange={() => setBillingisShipping(!billingIsShipping)}/>
      <label for="billing"> The billing address is the same as the shipping address</label>
      
      <div className='shipping_submit'>
      {!billingIsShipping && <Link to='/billing' onClick={sendShippingInfoToLocalStorage}>
        <input type="submit" value={`Billing ->`}/>
        </Link>}
      {billingIsShipping && <Link to='/pay' onClick={sendShippingInfoToLocalStorage}>
        <input type="submit" value={`Payment ->`}/>
        </Link>}
      </div>
    </form>
      </div>
    </div>;
}
