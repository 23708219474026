import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import './billing.css'

export default function BillingMobile() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
  
    useEffect(() => {
      if (localStorage.getItem('billing') !== null){
         const importedBillingObject = JSON.parse(localStorage.getItem('billing'))
         setFirstName(importedBillingObject['firstName'])
         setLastName(importedBillingObject['lastName'])
         setAddressLine1(importedBillingObject['addressLine1'])
         setAddressLine2(importedBillingObject['addressLine2'])
         setCity(importedBillingObject['city'])
         setState(importedBillingObject['state'])
         setZipcode(importedBillingObject['zipcode'])
         setPhoneNumber(importedBillingObject['phoneNumber'])
         setEmail(importedBillingObject['email'])
      }
    }, [])
    const sendBillingInfoToLocalStorage = () => {
      const billingObject = {
        'firstName': firstName,
        'lastName': lastName,
        'addressLine1': addressLine1,
        'addressLine2': addressLine2,
        'city': city,
        'state': state,
        'zipcode': zipcode,
        'phoneNumber': phoneNumber,
        'email': email,
      }
      localStorage.setItem('billing', JSON.stringify(billingObject))
    }
  
    return <div className='billing'>
        <h3>
            Billing Address
        </h3>
      <form action="" className='billing_form'>
        <label htmlFor="fname">First Name</label>
        <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)}placeholder='First Name..' />
  
        <label htmlFor="lname">Last Name</label>
        <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder='Last Name..' />
  
        <label htmlFor="address1">Street Address</label>
        <input type="text" value={addressLine1} onChange={e => setAddressLine1(e.target.value)} placeholder='1234 Street Ave...' />
  
        <label htmlFor="address2">Address Line 2</label>
        <input type="text" value={addressLine2} onChange={e => setAddressLine2(e.target.value)} placeholder='Apt 1...' />
  
        <label htmlFor="city">City</label>
        <input type="text" value={city} onChange={e => setCity(e.target.value)} placeholder='Cityville...' />
  
        <label htmlFor="state">State</label>
        <input type="text" value={state} onChange={e => setState(e.target.value)} placeholder='CA...' />
  
        <label htmlFor="zip">Zipcode</label>
        <input type="text" value={zipcode} onChange={e => setZipcode(e.target.value)} placeholder='12345...' />
  
        <label htmlFor="phone">Phone Number</label>
        <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} placeholder='(Optional) ...' />
  
        {/* <label htmlFor="email">Email</label>
        <input type="text" value={email} onChange={e => setEmail(e.target.value)}  placeholder='Needed...' /> */}
        
        <div className='shipping_submit'>
        <Link to='/pay' onClick={sendBillingInfoToLocalStorage}>
          <input type="submit" value={`Payment ->`}/>
          </Link>
        </div>
      </form>
      </div>;
}
