import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import './cart.css'

export default function CartMobile() {
    const price = 9.95
    const [total, setTotal] = useState(price)
    const [amount, setAmount] = useState(1)

    const handleAmount = (e) => {
        const amt = Number(e.target.value)
        setAmount(amt)
        const newTotal = (Math.round((amt * price) * 100) / 100).toFixed(2);
        setTotal(newTotal)
    }

    const sendCartInfoToLocalStorage = () => {
      const cartObject = {
        'total': total,
        'amount': amount
      }
      localStorage.setItem('cart', JSON.stringify(cartObject))
    }

  return (
    <div className='cart'>
    <h3 className='cart_header'>Your Cart</h3>
    <div className='cart_wrapper'>
    <div className='cart_left'>
    <img className='cover_cart' src="Book_Cover.jpg" alt="" />
    </div>
    <div className='cart_right'>
      <select className='checkout_amt_dropdown' onChange={handleAmount} name="amt" id="checkout_amt" value={amount}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
      </select>
      <div>Total: {total}</div>
    </div>
    </div>
    <div>*taxes and shipping included*</div>
    <div className='cart_bottom'>
      <Link to='/shipping' onClick={sendCartInfoToLocalStorage}>
      <button className='cart_continue'>{`shipping info ->`}</button>
      </Link>
    </div>
    </div>
  )
}
