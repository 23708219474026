import React from 'react';
import {createMedia} from "@artsy/fresnel"
import BlogMobile from '../../components/blog/BlogMobile';
import BlogDesktop from '../../components/blog/BlogDesktop';

export default function Blog() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <BlogMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BlogDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}