import React from 'react';
import {createMedia} from "@artsy/fresnel"
import ManageOrdersMobile from '../../components/manageOrders/ManageOrdersMobile';
import ManageOrdersDesktop from '../../components/manageOrders/ManageOrdersDesktop';

export default function ManageOrders() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <ManageOrdersMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <ManageOrdersDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}