import React from 'react';
import {createMedia} from "@artsy/fresnel"
import PostBlogMobile from '../../components/postBlog/PostBlogMobile';
import PostBlogDesktop from '../../components/postBlog/PostBlogDesktop';

export default function PostBlog() {
    const {MediaContextProvider, Media} = createMedia({
        breakpoints: {
          sm: 0, 
          lg: 1024,
        }
      })
  return <div>
          <MediaContextProvider>
              <Media at='sm'>
                <PostBlogMobile/>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <PostBlogDesktop/>
              </Media>
          </MediaContextProvider>
  </div>;
}