import React, {useEffect, useState, useRef} from 'react';
import http from '../../http-common.js'
import {Link} from 'react-router-dom'
import './blog.css'

export default function BlogDesktop() {
    const [data, setData] = useState([{author: 'loading', title: 'loading', body: 'loading'}])
    const [postIndex, setPostIndex] = useState(0)
    const maxPost = useRef(null)

    const [isAdmin, setisAdmin] = useState(false)

    useEffect(() => {
      isAdminCheck()
    }, [])
    
    const isAdminCheck = () => {
      if (localStorage.getItem('user') === null){
        setisAdmin(false);
      } else if (localStorage.getItem('user') !== null){
        const user = JSON.parse(localStorage.getItem('user'))
        if (user['isAdmin'] === true){
          setisAdmin(true)
        }
      }
    }
  
  const handleLogout = () => {
    localStorage.removeItem('user')
    setisAdmin(false)
  }

const handleString = (str) => {
    if (str.length >= 15){
        return str.substring(0,10) + '...'
    } else {
        return str
    }
  }

    useEffect(()=> {
        getLatestBlogOnLoad()
    }, [])

    const getLatestBlogOnLoad = async() =>{
        try{
        const latestBlog = await http.get('blog?latest=true')
        maxPost.current = latestBlog['data'].length - 1
        setData(latestBlog['data']) 
        } catch (error){
        console.error(error);
        }
    }

    const getNewerPost = () => {
        if (postIndex !== 0){
            setPostIndex(postIndex - 1)
        }
    }

    const getOlderPost = () => {
        if (postIndex !== maxPost.current){
            setPostIndex(postIndex + 1)
        }
    }
 
  return <>
         <div className='navbar'>
        <div className='wrapper_desktop'>
              <Link to='/' className='logo_desktop'> 
              M. A. Turner
              </Link>
              <div className='options_desktop'>
              <Link to='/cart' className='option_desktop' > Shop</Link>
                <Link to='/blog' className='option_desktop'>Blog</Link>
                <Link to='/#contact' className='option_desktop' >Contact</Link>
                {isAdmin && <Link to='/admin' className='option_desktop'> Admin</Link>}
                {isAdmin && <div className='option_desktop' onClick={handleLogout}>Logout</div>}
              </div>
      </div>
  </div>
      <div className='blog'>
        <div className='blog_desktop_wrapper'>
        <div className='blog_title_desktop'>{data[postIndex]["title"]}</div>
        <div className='blog_body_desktop'>{data[postIndex]["body"]}</div>
        </div>
      <div className='blog_button_group'>
      {postIndex !== 0 && <button className='change_post_button' onClick={getNewerPost}>{`<--`}{data[postIndex - 1] && handleString(data[postIndex - 1]["title"])}</button>}
      {postIndex !== maxPost.current && <button className='change_post_button' onClick={getOlderPost}>{data[postIndex + 1] && handleString(data[postIndex + 1]["title"])}{`-->`}</button>}
      </div>
      <div>---</div>
      </div>
      
      </>;
}
